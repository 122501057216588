import React from "react"
import Layout from "../../components/layout/Layout"
import Markdown from "../../components/markdown/Markdown"
import css from "./Aid.module.css"

export default ({ pageContext: { url, data } }) => {
  return (
    <Layout
      type="HMF"
      className={css.aid}
      slug={url}
      title="¿Eres víctima de violencia de género?"
    >
      <section className={css.header}>
        <div className="container">
          <h1 className={css.title}>¿Eres víctima de violencia de género?</h1>
          <div className="text-column-center">
            <p>{data.aid.descripcion.descripcion}</p>
          </div>
        </div>
      </section>
      {data.aid.acciones.map((accion, i) => (
        <section key={i} className={css.accion}>
          <div key={i} className="container">
            <div className="text-column mb-64">
              <h2 className={css.actionName}>{accion.nombre}</h2>
              <Markdown>{accion.cuerpo.childMarkdownRemark.html}</Markdown>
            </div>
            {accion.instituciones.map((institucion, i) => (
              <div key={i} className={css.institucion}>
                <h3 className={css.institutionName}>
                  {institucion.nombre.nombre}
                </h3>
                <Markdown>
                  {institucion.descripcion.childMarkdownRemark.html}
                </Markdown>
              </div>
            ))}
          </div>
        </section>
      ))}
    </Layout>
  )
}
